//定义全局环境常量
module.exports = {
    //程序名称
    APP_NAME: "psim",

    //数据接口请求地址
    BASE_API_URL: "http://www.cpssm.com",

    //前端服务端口
    SERVER_PROT: 80,

    //服务器ip地址
    SERVER_HOST: "127.0.0.1"
};

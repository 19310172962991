import Vue from "vue";
import Router from "vue-router";
const global = require('../config/global.env')
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: "login",
      path: "/login",
      component: () => import("@/view/pages/login/Index.vue")
    },
    {
      path: "/",
      redirect: "index",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/index",
          name: "index",
          component: () => import("@/view/pages/index/Index.vue")
        },
        {
          path: "/menu",
          name: "menu",
          component: () => import("@/view/pages/menu/Index.vue")
        },
        {
          path: "/rule",
          name: "rule",
          component: () => import("@/view/pages/rule/Index.vue")
        },
        {
          path: "/role",
          name: "role",
          component: () => import("@/view/pages/role/Index.vue")
        },
        {
          path: "/adminUser",
          name: "adminUser",
          component: () => import("@/view/pages/adminUser/Index.vue")
        },
        {
          path: "/site",
          name: "site",
          component: () => import("@/view/pages/site/Index.vue"),
          children: [
              {
                path: "/site/authsite",
                name: "authsite",
                component: () => import("@/view/pages/site/AuthSite.vue"),
              }
          ]
        },
        {
          path: "/product",
          name: "product",
          component: () => import("@/view/pages/product/Index.vue"),
          children: [
            {
              path: "/product/addproduct",
              name: "addproduct",
              component: () => import("@/view/pages/product/AddProduct.vue"),
            },
            {
              path: "/product/updateproduct",
              name: "updateproduct",
              component: () => import("@/view/pages/product/UpdateProduct.vue"),
            }
          ]
        },
        {
          path: "/warehouse",
          name: "warehouse",
          component: () => import("@/view/pages/warehouse/Index.vue"),
          children: [

          ]
        },
        {
          path: "/shelf",
          name: "shelf",
          component: () => import("@/view/pages/shelf/Index.vue"),
          children: [

          ]
        },
        {
          path: "/stock",
          name: "stock",
          component: () => import("@/view/pages/stock/Index.vue"),
          children: [

          ]
        },
        {
          path: "/ledger",
          name: "ledger",
          component: () => import("@/view/pages/ledger/Index.vue"),
          children: [

          ]
        },
        {
          path: "/checkwarehouse",
          name: "checkwarehouse",
          component: () => import("@/view/pages/checkwarehouse/Index.vue"),
          children: [
            {
              path: "/checkwarehouse/check",
              name: "check",
              component: () => import("@/view/pages/checkwarehouse/Check.vue"),
            },
            {
              path: "/checkwarehouse/showcheck",
              name: "showcheck",
              component: () => import("@/view/pages/checkwarehouse/ShowCheck.vue"),
            },
          ]
        },
        {
          path: "/warehouseallot",
          name: "warehouseallot",
          component: () => import("@/view/pages/warehouseallot/Index.vue"),
          children: [

          ]
        },
        {
          path: "/supplier",
          name: "supplier",
          component: () => import("@/view/pages/supplier/Index.vue"),
          children: [

          ]
        },
        {
          path: "/purchasement",
          name: "purchasement",
          component: () => import("@/view/pages/purchasement/Index.vue"),
          children: [
            {
              path: "/purchasement/addpurchasement",
              name: "addPurchasement",
              component: () => import("@/view/pages/purchasement/AddPurchasement.vue"),
            },
            {
              path: "/purchasement/updatepurchasement",
              name: "updatePurchasement",
              component: () => import("@/view/pages/purchasement/UpdatePurchasement.vue"),
            },
            {
              path: "/purchasement/showpurchasement",
              name: "showPurchasement",
              component: () => import("@/view/pages/purchasement/ShowPurchasement.vue"),
            },
            {
              path: "/purchasement/rootpurchasement",
              name: "rootPurchasement",
              component: () => import("@/view/pages/purchasement/RootPurchasement.vue"),
            },
          ]
        },
        {
          path: "/interconfig",
          name: "interconfig",
          component: () => import("@/view/pages/interconfig/Index.vue"),
          children: [

          ]
        },
        {
          path: "/intersetting",
          name: "intersetting",
          component: () => import("@/view/pages/intersetting/Index.vue"),
          children: [

          ]
        },
        {
          path: "/order",
          name: "order",
          component: () => import("@/view/pages/order/Index.vue"),
          children: [
            {
              path: "/order/addorder",
              name: "addOrder",
              component: () => import("@/view/pages/order/AddOrder.vue"),
            },
            {
              path: "/order/showorder",
              name: "showOrder",
              component: () => import("@/view/pages/order/ShowOrder.vue"),
            },
            {
              path: "/order/updateorder",
              name: "updateOrder",
              component: () => import("@/view/pages/order/UpdateOrder.vue"),
            },
            {
              path: "/order/expressesorder",
              name: "expressesOrder",
              component: () => import("@/view/pages/order/ExpressesOrder.vue"),
            },
          ]
        },
        {
          path: "/refund",
          name: "refund",
          component: () => import("@/view/pages/refund/Index.vue"),
          children: [

          ]
        },
      ]
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/404.vue")
    },
    {
      path: "/401",
      name: "401",
      component: () => import("@/view/pages/error/401.vue")
    },
    {
      path: '*',
      redirect: '/404',
      name: 'notFound',
      hidden: true
    }
  ]
});



//路由登录验证
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    let token = localStorage.getItem(global.APP_NAME + '_Authorization_Token');
    if (token === 'null' || token === '' || !token) {
      next('/login');
    } else {
      next();

    }
  }
});
export default router;

